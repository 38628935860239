import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataView } from 'primereact/dataview';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import CartItem from '../../models/CartItem';
import { EmailService } from '../../services/email.service';
import { PurchaseOrderService } from '../../services/purchaseOrder.service';
import { Modal } from '../Modal/Modal';
import { AuthService } from '../../services/auth.service';
import './SideBar.css';
import { DelegateService } from '../../services/delegate.service';
import { KamService } from '../../services/kam.service';

interface SideBarProps {
  visibleSidebar: boolean;
  setVisibleSideBar: (visible: boolean) => void;
}

export default function SideBar({
  visibleSidebar,
  setVisibleSideBar,
}: SideBarProps) {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const {
    cartItems,
    increaseCartQuantity,
    decreaseCartQuantity,
    setQuantity,
    removeFromCart,
    emptyCart,
  } = useShoppingCart();

  const toast = useRef<Toast>(null);
  const navigate = useNavigate();


  const accept = async () => {
    try {
      await purchaseItems();
      await sendEmail();
      const role = AuthService.GetRole(); 
      if (role == "Delegate" || role == "KAM") {
        navigate('/choiceCustomer');
      }
    } catch (error) {
      console.error('Error occurred while processing:', error);
      toast.current?.show({
        severity: 'error',
        summary: t('sidebar_component.toast_error_purchase.summary'),
        detail: t('sidebar_component.toast_error_purchase.detail'),
        life: 3000,
      });
    }
  };

  async function purchaseItems() {
    try {
      //TODO - Paula - Some fields here can be erased and changed.
      const address = AuthService.GetUserEmail() || ''; 
      let idCompany = ''; 
      let idClient = ''; 
      const role = AuthService.GetRole(); 
      if(role === 'Editor'){
        idCompany = AuthService.GetCompanyID() || ''; 
        idClient = AuthService.GetCustomerID() || ''; 
      }
      else if (role === 'Delegate'){
        idCompany = DelegateService.GetDelegateCompanyID() || ''; 
        idClient = DelegateService.GetDelegateCustomerID() || ''; 
      }
      else {
        idCompany = KamService.GetKamCompanyID() || ''; 
        idClient = KamService.GetKamCustomerID() || ''; 
      }
      const newPurchaseItemsAX = cartItems.map((cartItem) => {
        return {
          idPurchaseOrder: `P2B_${Date.now()}`,
          idCompany: idCompany,
          idClient: idClient,
          partition: 5637144576,
          recVersion: 1,
          quantity: cartItem.quantity,
          price: cartItem.price,
          code: cartItem.idProduct.toString(),
          address: address,
        };
      });

      await PurchaseOrderService.PostPurchaseOrderAX(newPurchaseItemsAX);

      toast.current?.show({
        severity: 'info',
        summary: t('sidebar_component.toast_confirmation_purchase.summary'),
        detail: t('sidebar_component.toast_confirmation_purchase.detail'),
        life: 3000,
      });

      emptyCart();
      setVisibleModal(false);
    } catch (error) {
      console.error('Error occurred while posting purchase orders:', error);
      throw new Error('Purchase order submission failed');
    }
  }

  async function sendEmail() {
    try {
      const idLanguageEmail = AuthService.GetLanguageID() || '';
      const userMail = AuthService.GetUserEmail() || '';
      await EmailService.Send(userMail, idLanguageEmail);
    } catch (error) {
      console.error('Error occurred while sending email:', error);
      throw new Error('Email sending failed');
    }
  }


  const handleQuantityChange = (idProduct: string, quantity: number) => {
    if (quantity <= 0) {
      removeFromCart(idProduct);
    } else {
      setQuantity(idProduct, quantity);
    }
  };

  const itemTemplate = (cartItems: CartItem[], layout: string | undefined) => {
    return cartItems.map((product, index) => (
      <div key={product.idProduct}>
        <div
          className={classNames(
            'flex flex-column xl:flex-row xl:align-items-start p-4 gap-4',
            { 'border-top-1 surface-border': index !== 0 },
          )}
        >
          <img
            className="w-9 sm:w-10rem xl:w-8rem shadow-2 block xl:block mx-auto border-round"
            src={product.picture}
            alt={product.displayName}
            style={{
              maxWidth: '40px',
              maxHeight: '70px',
              width: 'auto',
              height: 'auto',
            }}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = 'https://res.cloudinary.com/dkfvgi7bh/image/upload/v1717055104/nfgnbce4ub9k6tftpyfy.jpg';
            }}
          />
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
              <div
                className="text-s font-normal text-900"
                style={{ fontFamily: 'Graphik Light', fontSize: 'small' }}
              >
                {product.displayName.toLocaleLowerCase()}
              </div>
              <div className="counter-div">
                <p
                  className="icon-substract-add"
                  onClick={() => decreaseCartQuantity(product.idProduct)}
                >
                  -
                </p>
                <input
                  type="text"
                  value={product.quantity}
                  className="sidebar-input"
                  disabled={product.lowest > 1}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (!isNaN(value)) {
                      handleQuantityChange(product.idProduct, value);
                    }
                  }}
                />
                <p
                  className="icon-substract-add"
                  onClick={() =>
                    increaseCartQuantity(
                      product.idProduct,
                      product.displayName,
                      product.price,
                      product.picture,
                      product.lowest,
                      product.multiple,
                    )
                  }
                >
                  +
                </p>
                <i
                  className="pi pi-trash"
                  onClick={() => removeFromCart(product.idProduct)}
                ></i>
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
              <span
                className="text-l font-normal"
                style={{ fontFamily: 'Graphik Light', fontSize: 'small' }}
              >
                {product.price.toFixed(2)}€
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="card flex justify-content-center">
      <Sidebar
        visible={visibleSidebar}
        onHide={() => setVisibleSideBar(false)}
        position="right"
        style={{ position: 'relative', zIndex: 9999 }}
      >
        <h2 className="sidebar-title">{t('sidebar_component.title')}</h2>
        <DataView value={cartItems} listTemplate={itemTemplate}></DataView>
        <Toast ref={toast} />
        <ConfirmDialog />
        <div
          className="card flex flex-wrap gap-2 justify-content-center"
          style={{ marginTop: 'auto', marginBottom: '10rem' }}
        >
          <Divider></Divider>
          <div
            className="text-s font-normal text-900"
            style={{ fontFamily: 'Graphik Light' }}
          >
            <strong>{t('sidebar_component.total')}</strong>{' '}
            {cartItems
              .reduce(
                (accumulator, currentItem) =>
                  accumulator + currentItem.price * currentItem.quantity,
                0,
              )
              .toFixed(2)}{' '}
            €
          </div>
          <Divider />
        </div>
        <div
          className="card flex flex-wrap gap-2 justify-content-center"
          style={{ marginTop: '1rem' }}
        >
          <button
            className={`meso-button-sidebar${cartItems.length === 0 ? '-disabled' : ''}`}
            disabled={cartItems.length === 0}
            onClick={() => {
              setVisibleModal(true);
            }}
          >
            {t('sidebar_component.purchase_order_btn')}
          </button>
          {visibleModal && (
            <Modal
              onAccept={accept}
              onCancel={() => setVisibleModal(false)}
              onClose={() => setVisibleModal(false)}
              items={
                <p>{`${cartItems.length} ${t('sidebar_component.confirm_dialog.items')}`}</p>
              }
            >
              <p>{t('sidebar_component.confirm_dialog.question')}</p>
            </Modal>
          )}
          <button
            className="meso-button-sidebar"
            onClick={() => {
              setVisibleSideBar(false);
            }}
          >
            {t('sidebar_component.hide_sidebar')}
          </button>
        </div>
      </Sidebar>
    </div>
  );
}
