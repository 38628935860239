import axios from 'axios';
import Customer from '../models/Customer';


export const KamService = {
  
  GetCustomersOfKam: async (email: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/kam?email=${email}`,
    );
    return response;
  },
  SetKamCustomerInformation: (kamCustomerChosen: Customer) => {
    localStorage.setItem('KamCustomerID', kamCustomerChosen.account); 
    localStorage.setItem('KamCompanyID', kamCustomerChosen.idCompany); 
    localStorage.setItem('KamCustomerName', kamCustomerChosen.nameClient); 
  },
  GetKamCustomerID: () => {
    return localStorage.getItem('KamCustomerID'); 
  }, 
  GetKamCompanyID: () => {
    return localStorage.getItem('KamCompanyID'); 
  }, 
  GetKamCustomerName: () => {
    return localStorage.getItem('KamCustomerName'); 
  }
  

};
