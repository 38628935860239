import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Splash.css';
import { AuthContext } from '../../context/AuthContext';
import { AuthService } from '../../services/auth.service';

const SplashScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null); 
  const [username, setUsername] = useState(''); 
  const [password, setPassword] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext); 

  const handleLogin = async () => {
    setIsLoading(true); 
    setError(null);

    try {
      await authContext?.login(username, password);

      
      const role = AuthService.GetRole();
      console.log(role); 

      if (role === 'KAM' || role === 'Delegate') {
        navigate('/choiceCustomer');
      } else {
        navigate('/products');
      }
    } catch (err) {
      setError(t('splash.toasts.error_database.detail')); 
    } finally {
      setIsLoading(false); 
    }
  }; 
  


  useEffect(() => {
    if (authContext?.isAuthenticated && authContext?.isValidated) {
      const role = AuthService.GetRole();
      console.log("ROLE" + role);

      if (role === 'KAM' || role === 'Delegate') {
        navigate('/choiceCustomer');
      } else {
        navigate('/products');
      }
      setIsLoading(false);
    }
  }, [authContext?.isAuthenticated, navigate]); 

  return (
    <div className="background">
      <img
        alt="background"
        className="long-logo"
        src="/images/logo_mesoestetic.png"
      />
      <img
        alt="name-logo"
        className="name-logo"
        src="/images/merchandising.png"
      />
      <div className="form-container">
        {isLoading ? (
          <div className="card flex justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            {error && <div className="error">{error}</div>}
            <input 
              type="text" 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              className="input-field"
            />
            <input 
              type="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="input-field"
            />
            <button
              type="submit"
              className="microsoft-button"
              onClick={handleLogin}
            >
              {t('splash.login')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
