import DarkMenuBar from '../../components/DarkMenuBar/DarkMenuBar';
import DropdownAutocomplete from '../../components/AutocompleteSearch/DropdownAutocomplete';

export default function ChoiceCustomer() {
  return (
    <div>
      <DarkMenuBar />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DropdownAutocomplete></DropdownAutocomplete>
      </div>
    </div>
  );
}
