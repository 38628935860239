import axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import CustomerContact from '../models/CustomerContact';
import { useNavigate } from 'react-router-dom';

interface JwtPayload{
  sub:string; 
}
export const AuthService = {
  Login: async (email: string, password: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/api/account/app-login`, {
        email,
        password,
        ApplicationName: 'Merchan Platform'
      });
      console.log(response); 
      const { token } = response.data;

      const decodedToken: JwtPayload = jwtDecode(token);
      const userEmail = decodedToken.sub;

      localStorage.setItem('jwtToken', token);
      localStorage.setItem('userEmail', userEmail);

      return token;
    } catch (error) {
      console.error('Error en el login', error);
      throw new Error('Login failed');
    }
  },
  ValidateEmail: async () => {
    try{
      const token = AuthService.GetAuthToken(); 
      const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}/api/Account/auth/email`, 
        {
          App: 'Merchan', 
          Email: AuthService.GetUserEmail(), 
          Role:  'Portal Pedidos', //TODO - Paula - Ponerlo como variable de entorno.
        }, 
        {
          headers: {
            ContentType: 'application/json', 
            Authorization: `Bearer ${token}`,
          }
        }
      ); 
      if (response.status === 0){
        console.error('Error al validar email - auth.service'); 
        return; 
      }

      const data = response.data as CustomerContact; 

      localStorage.setItem('CustomerID', data.CustomerID || ''); 
      localStorage.setItem('Role', data.Roles[0]); 
      localStorage.setItem('CompanyID', data.CompanyID || ''); 
      localStorage.setItem('LanguageID', data.LanguageID || 'es');
      localStorage.setItem('CustomerName', data.CustomerName || ''); 
    }
    catch(error){
      console.error('Error al validar el email.', error); 
    }

  },
  LogOut: () => {
    if(AuthService.GetRole() == "Delegate"){
      localStorage.removeItem("DelegateCompanyID"); 
      localStorage.removeItem("DelegateCustomerID");
      localStorage.removeItem("DelegateEmail");
      localStorage.removeItem("DelegateName");
    }
    else if (AuthService.GetRole() == "KAM"){
      localStorage.removeItem("KamCustomerID"); 
      localStorage.removeItem("KamCompanyID");
      localStorage.removeItem("KamCustomerName");
      localStorage.removeItem("KamEmail");
    }
    localStorage.removeItem('jwtToken'); 
    localStorage.removeItem('userEmail'); 
    localStorage.removeItem('CompanyID'); 
    localStorage.removeItem('CustomerID'); 
    localStorage.removeItem('CustomerName'); 
    localStorage.removeItem('Role'); 
    localStorage.removeItem('LanguageID'); 
  }, 
  GetAuthToken: () => {
    return localStorage.getItem('jwtToken'); 
  },
  GetUserEmail: () => {
    return localStorage.getItem('userEmail'); 
  },
  GetCustomerID: () => {
    return localStorage.getItem('CustomerID'); 
  },
  GetCustomerName: () => {
    return localStorage.getItem('CustomerName');
  },
  GetCompanyID: () => {
    return localStorage.getItem('CompanyID'); 
  }, 
  GetLanguageID: () => {
    return localStorage.getItem('LanguageID'); 
  },
  GetRole: () => {
    return localStorage.getItem('Role'); 
    //return "Delegate"; 

  }, 
  IsAuthenticated: () => {
    const token = AuthService.GetAuthToken(); 
    return token != null; 
  }, 
  Test: async (token: string) => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/api/product/test/${token}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }); 
      return response;
    }
    catch(error){
      if(error instanceof AxiosError){
        throw error; 
      }
      throw new Error('An unexpected error occured.'); 
    }

  }

};
