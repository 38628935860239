import React from 'react';
import Customer from '../../models/Customer';
import { ScrollPanel } from 'primereact/scrollpanel';
import './ClientsList.css';

type ClientListProps = {
  clients: Customer[];
  onClientClick: (client: Customer) => void;
};

export default function ClientsList({
  clients,
  onClientClick,
}: ClientListProps) {
  return (
    <div className="dropdown-list">
      <ScrollPanel style={{ width: '100%', height: '250px' }}>
        {clients.map((client) => (
          <div
            key={client.account}
            className="client-item"
            onClick={() => onClientClick(client)}
          >
            <p>
              <strong>{client?.nameClient ? client.nameClient.toLocaleLowerCase() : 'Unnamed Client'}</strong>
            </p>
            <hr className="styled-hr"></hr>
          </div>
        ))}
      </ScrollPanel>
    </div>
  );
}
