import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/auth.service';
import { DelegateService } from '../../services/delegate.service';
import ClientsList from './ClientsList';
import './DropdownAutocomplete.css';
import { KamService } from '../../services/kam.service';
import Customer from '../../models/Customer';

export default function DropdownAutocomplete() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [inputQuery, setInputQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showList, setShowList] = useState(false);
  const [isKam, setIsKam] = useState(false); 
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    const userEmail = AuthService.GetUserEmail() || ''; 
    const roleUser = AuthService.GetRole(); 
    if (roleUser === 'Delegate') {
      setIsKam(false); 
      DelegateService.GetCustomersOfDelegate(userEmail).then((response) => {
        console.log("CLIENTES DELEGAO" + response.data)
        setCustomers(response.data as Customer[]);
        setSearchResults(response.data as Customer[]);
      });
    }
    else if (roleUser === 'KAM') {
      console.log("hola estoy aqui, soy un kam!")
      setIsKam(true); 
      KamService.GetCustomersOfKam(userEmail).then((response) => {
        console.log("Hola papasito esta es mi response"+response.data); 
        setCustomers(response.data as Customer[]); 
        setSearchResults(response.data as Customer[]); 
      }); 
    }
    console.log("HEYY THIS IS INPUT DATA"+customers)

    
  }, []);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    console.log("HEYY THIS IS INPUT DATA"+customers)
    setInputQuery(event.target.value);
    setSearchResults(
      customers.filter((client) =>
        client?.nameClient
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()),
      ),
    );  
  }

  function handleClientClick(customerChosen: Customer) {
    if(isKam) {
      saveCustomerKamInformation(customerChosen); 
    }
    else{
      saveCustomerDelegateInformation(customerChosen);
    }
    navigate('/products');
  }

  function saveCustomerDelegateInformation(delegateCustomerChosen: Customer) {
    DelegateService.SetDelegateCustomerInformation(delegateCustomerChosen); 
  }
  function saveCustomerKamInformation(kamCustomerChosen: Customer) {
    KamService.SetKamCustomerInformation(kamCustomerChosen); 
  }

  return (
    <div style={{ width: '100%', maxWidth: '400px', position: 'relative' }}>
      <div className="input-container">
        <input
          type="text"
          className="styled-input"
          placeholder={t('dropdown_autocomplete.input')}
          onChange={handleInputChange}
          onFocus={() => setShowList(true)} 
        />
        <i
          className="pi pi-angle-down icon"
          onClick={() => setShowList(!showList)}
        ></i>
      </div>

      {(showList || (inputQuery !== '' && searchResults.length > 0)) && (
        <div className="dropdown-list">
          <ClientsList
            clients={searchResults}
            onClientClick={handleClientClick}
          />
        </div>
      )}
    </div>
  );
}
