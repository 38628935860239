import axios from 'axios';
import Customer from '../models/Customer';


export const DelegateService = {
  
  GetCustomersOfDelegate: async (email: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/delegates?email=${email}`,
    );
    return response;
  },
  SetDelegateCustomerInformation: (delegateCustomerChosen: Customer) => {
    localStorage.setItem('DelegateCustomerID', delegateCustomerChosen.account); 
    localStorage.setItem('DelegateCompanyID', delegateCustomerChosen.idCompany); 
    localStorage.setItem('DelegateName', delegateCustomerChosen.nameClient); 
  },
  GetDelegateCustomerID: () => {
    return localStorage.getItem('DelegateCustomerID'); 
  }, 
  GetDelegateCompanyID: () => {
    return localStorage.getItem('DelegateCompanyID'); 
  }, 
  GetDelegateName: () => {
    return localStorage.getItem('DelegateName'); 
  }
  

};
